import * as React from "react"
import { Link } from "gatsby"
import { Row, Container, Col } from "react-bootstrap"

import YAMLdata from "./coming-soon.yaml"

const ComingSoon = () => (
  <section className="category_area pad_btm">
    <div className="category_outer">
      <Container className="p0" fluid>
        <Row className="no-gutters">
          <Col className="category_image_outer">
            <div className="category_image_inner">
              <div className="category_image coming_soon_bg">
                <div className="coming_soon_box">
                  <h2>{YAMLdata.title}</h2>
                  <br />
                  <br />
                  {/* <p>{YAMLdata.content}</p> */}
                  <Link to="/projects">back to projects</Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </section>
)

export default ComingSoon
